import React from 'react';
import P from 'prop-types';

import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SubFooter from '../components/SubFooter';
import '../scss/style.scss';

const Layout = (props) => {
  const { children, bodyClass } = props;

  return (
    <>
      <SEO />
      <div className={`page${bodyClass ? ` ${bodyClass}` : ''}`}>
        <div id="wrapper" className="wrapper">
          <Header />
          {children}
        </div>
        <Footer />
        <SubFooter />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: P.node.isRequired,
  bodyClass: P.string.isRequired,
};

export default Layout;
